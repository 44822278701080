body {
    font-size: 20px;
}
.header {
    /*position: fixed;*/
    top: 0;
    width: 100%;
    text-align: center;
    background-color: rgb(9, 94, 84);
    color: white;
    padding: 20px;
}

.main {
    display: grid;
    height: 800px;    
    padding-top: 130px;
    /* grid-template-columns: 1fr 6fr; */
    grid-template-columns: repeat(auto-fit, minmax(479px, auto));
}

.nav {
    background-color: rgb(9, 94, 84);
    text-align: center;
}

.content {
    background-color: rgb(9, 94, 84);
}

/*
.footer {
    height: 400px;
    text-align: center;
    padding: 20px;
    background-color: rgb(223, 223, 223);
}
*/
.bot {
    bottom: 0;
    right : 0;
    position: fixed;
    width: 400px;
}

.btn {
    color: rgba(240, 248, 255, 0);
    border: none;
    font-size: 20px;
    float: right;
    width: 40%;
    padding: 35px;
    background-color: rgba(92, 86, 94, 0)
}
.rsc-header-title {
    color: #ffffff; 
}

.rsc-header-title::before {
  content: ""; /*Isso cria um conteúdo vazio para o pseudo-elemento*/
  display: block;  /*Garante que o pseudo-elemento seja exibido como um bloco*/
  width: 50px; /* Define a largura da "div" simulada */
  height: 50px; /* Define a altura da "div" simulada */
  background-color: rgb(245, 245, 245); /* Define a cor de fundo da "div" simulada */
  border-radius: 50%;
  background-image: url("./favicon.png");
  background-size: 85%;
  background-repeat: no-repeat;
  background-position: center;
}

.rsc-header-title{
  content: "a";
}
.rsc-header-title::after{

    content: "Hospital do Olho";
    padding-left: 2rem;
    font-size: 15pt;
}
.card{
  width: 60%;
  height: 60%;
}
.img-telefone{
  background-image: url(./telefone.jpeg);
  border-radius: 10px;
  width: 100%;
  height: 100px;
  align-items: center;
  align-content: center;
  display: flex;
  object-fit: cover;
}
.kHwveM {
  background-color: #000000;
}
.hDUXUW {
  background-color: #0f0e0e;
}
.bWZJeP {
  background-color: #00f635;
  fill: #353635;
}
.jKLQUD {
  background: #fff0;
  border-radius: 5px;
  box-shadow: rgba(0,0,0,0.15) 0px 1px 2px 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 6px 10px 6px;
  padding: 16px;
  border: none;
}

._header-module__logo__3jDIS {
    align-items: center;
    align-self: center;
    background: #fff;
    border-radius: 50%;
    color: cadetblue;
    display: flex;
    font-size: 2em;
    height: 45px;
    margin: 5px;
    justify-content: center;
    padding: 10px;
    width: 45px;
}

/* sc-component-id: sc-bdVaJa */
.hseFPs {
    -webkit-animation: yGVCZ 1.4s infinite both;
    animation: yGVCZ 1.4s infinite both;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  
  .iTWArB {
    -webkit-animation: yGVCZ 1.4s infinite both;
    animation: yGVCZ 1.4s infinite both;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
  }
  
  .dUwKYp {
    -webkit-animation: yGVCZ 1.4s infinite both;
    animation: yGVCZ 1.4s infinite both;
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
  }
  
  /* sc-component-id: sc-bwzfXH */
  
  /* sc-component-id: sc-htpNat */
  .GgOGn {
    -webkit-animation: Lmuha 0.3s ease forwards;
    animation: Lmuha 0.3s ease forwards;
    cursor: pointer;
    display: inline-block;
    margin: 2px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  
  /* sc-component-id: sc-bxivhb */
  .jdhdOZ {
    background: #2fb85d;
    border-radius: 22px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    color: #fff;
    display: inline-block;
    font-size: 14px;
    padding: 12px;
  }
  
  .jdhdOZ:hover {
    opacity: 0.7;
  }
  
  /* sc-component-id: sc-ifAKCX */
  .gkhNlr {
    margin: 2px 0 12px 0;
    padding: 0 6px;
  }
  
  /* sc-component-id: sc-EHOje */
  
  /* sc-component-id: sc-bZQynM */
  .hQsUiY {
    -webkit-animation: Lmuha 0.3s ease forwards;
    animation: Lmuha 0.3s ease forwards;
    background: rgb(255, 255, 255);
    border-radius: 18px 18px 18px 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    color: #0f0e0e;
    display: inline-block;
    font-size: 14px;
    max-width: 50%;
    margin: 0 0 10px 0;
    overflow: hidden;
    position: relative;
    padding: 12px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
  }
  
  .iOCYje {
    -webkit-animation: Lmuha 0.3s ease forwards;
    animation: Lmuha 0.3s ease forwards;
    background: #fff;
    border-radius: 18px 18px 0 18px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    color: #4a4a4a;
    display: inline-block;
    font-size: 14px;
    max-width: 50%;
    margin: 0 0 10px 0;
    overflow: hidden;
    position: relative;
    padding: 12px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  
  /* sc-component-id: sc-gzVnrw */
  /*avatar chat*/
  .cwuCQv {
    -webkit-animation: Lmuha 0.3s ease forwards;
    animation: Lmuha 0.3s ease forwards;
    border-radius: 50% 50% 0 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    height: 40px;
    min-width: 40px;
    padding: 3px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
    width: 40;
  }
  
  .eijbEu {
    -webkit-animation: Lmuha 0.3s ease forwards;
    animation: Lmuha 0.3s ease forwards;
    border-radius: 50% 50% 50% 0;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    height: 40px;
    min-width: 40px;
    padding: 3px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    transform-origin: bottom left;
    width: 40;
  }
  
  /* sc-component-id: sc-htoDjs */
  .vmYlS {
    display: inline-block;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    padding: 6px;
  }
  
  .fmWSyf {
    display: inline-block;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    padding: 6px;
  }
  
  /* sc-component-id: sc-dnqmqq */
  .efROPc {
    -webkit-align-items: flex-end;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  .eBYsSN {
    -webkit-align-items: flex-end;
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  
  /* sc-component-id: sc-iwsKbI */
  .dPdbIW {
    background: #f5f8fb;
    border-radius: 10px;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
    font-family: monospace;
    overflow: hidden;
    position: relative;
    bottom: initial;
    top: initial;
    right: initial;
    left: initial;
    width: 350px;
    height: 560px;
    z-index: 999;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
    -webkit-transition: -webkit-transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
  }
  
  @media screen and (max-width:568px) {
    .dPdbIW {
      bottom: 0 !important;
      left: initial !important;
      height: 100%;
      right: 0 !important;
      top: initial !important;
      width: 100%;
    }
  }
  
  /* sc-component-id: sc-gZMcBi */
  
  .fBGGBn {
    height: calc(520px - 112px);
    overflow-y: scroll;
    margin-top: 2px;
    padding-top: 6px;
    background-image: url(./background.png);
  }
  
  @media screen and (max-width:568px) {}
  
  /* sc-component-id: sc-gqjmRU */
  .glfuN {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: rgb(9, 94, 84);
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    fill: #fff;
    height: 100px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 10px;
  }
  
  /* sc-component-id: sc-VigVT */
  .lifvqk {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 15px;
  }
  
  /* sc-component-id: sc-jTzLTM */
  
  /* sc-component-id: sc-fjdhpX */
  
  /* sc-component-id: sc-jzJRlG */
  
  /* sc-component-id: sc-cSHVUG */
  .hDUXUW {
    position: relative;
  }
  
  /* sc-component-id: sc-kAzzGY */
  .iAuOXT {
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #eee;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 16px;
    opacity: .5;
    outline: none;
    padding: 16px 52px 16px 10px;
    width: 100%;
    -webkit-appearance: none;
  }
  
  .iAuOXT:disabled {
    background: #fff;
  }
  
  @media screen and (max-width:568px) {
    .iAuOXT {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  
  .jpJyWr {
    
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid #eee;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 16px;
    opacity: 1;
    outline: none;
    padding: 16px 52px 16px 10px;
    width: 100%;
    -webkit-appearance: none;
  }
  .jpJyWr:after{
    content: "Digite sua mensagem";
  }
  
  .jpJyWr:disabled {
    background: #fff;
  }
  
  @media screen and (max-width:568px) {
    .jpJyWr {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  
  /* sc-component-id: sc-chPdSV */
  .iUYVrA {
    background-color: transparent;
    border: 0;
    border-bottom-right-radius: 10px;
    box-shadow: none;
    cursor: default;
    fill: #4a4a4a;
    opacity: .5;
    outline: none;
    padding: 14px 16px 12px 16px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .iUYVrA:before {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    border-radius: 50%;
  }
  
  .iUYVrA:not(:disabled):hover {
    opacity: 0.7;
  }
  
  .hiexti {
    background-color: transparent;
    border: 0;
    border-bottom-right-radius: 10px;
    box-shadow: none;
    cursor: pointer;
    fill: #4a4a4a;
    opacity: 1;
    outline: none;
    padding: 14px 16px 12px 16px;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .hiexti:before {
    content: '';
    position: absolute;
    width: 23px;
    height: 23px;
    border-radius: 50%;
  }
  
  .hiexti:not(:disabled):hover {
    opacity: 0.7;
  }
  
  /* sc-component-id: sc-keyframes-Lmuha */
  @-webkit-keyframes Lmuha {
    100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @keyframes Lmuha {
    100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
  
  /* sc-component-id: sc-keyframes-yGVCZ */
  @-webkit-keyframes yGVCZ {
    0% {
      opacity: .2;
    }
  
    20% {
      opacity: 1;
    }
  
    100% {
      opacity: .2;
    }
  }
  
  @keyframes yGVCZ {
    0% {
      opacity: .2;
    }
  
    20% {
      opacity: 1;
    }
  
    100% {
      opacity: .2;
    }
  }